<template>
    <div id="app">
        <img id="img1" src="../assets/uploadPictures.png" alt="">
        <h2>{{ mode === "english"? "Collect Your Bird Photos" :"征集你的鸟类照片"}}</h2>
        <p id="p1">{{ mode ==="english" ?"Welcome to Contribute Pictures": "欢迎贡献图片" }}</p>
        <div id="input-container">
        <input type="text" :placeholder="pl1" v-model="name">
        <input type="text" :placeholder="pl2" v-model="birdName">
        <input type="text" :placeholder="pl3" v-model="location">
        <div id="file-upload"
        @dragenter.prevent="highlight"
        @dragover.prevent="highlight"
        @dragleave.prevent="unhighlight"
        @drop.prevent="handleDrop"
        @mouseleave="unhighlight"
        :class = "{hover: isHovering}"
        >
        <img src="../assets/uploadComponent.png" alt="">
        <p>{{mode === "english" ? "Drag and Drop": "拖拽照片"}}</p>
        <p>{{mode === "english" ? "or": "或者"}}</p>
        <input type="file" id="file-upload1" @change="onFileChange" ref="fileInput" style="display: none;" />
        <label for="file-upload1" id="labelForSelect">{{mode === "english" ? "Select Picture": "选择照片"}}</label>
        <i v-if="file" style="color:gray; margin-top:10px">{{ mode === "english" ? "File Name: " : "文件名称: " }} {{ file.name }}</i>
        </div>
        <button id="submit" @click="submitForm">{{mode === "english" ? "Submit": "提交"}}</button>
    </div>
    <PopupWindow :showModal="showModal" width="400px" @deleteSM = "deleteModal">
        <template #content>
            <div id="success" v-if="isSuccess">
                <span>Success!</span>
            </div>
            <div id="success" v-if="!isSuccess">
                <span>Failed!</span>
                <p>Incomplete&Incorrect Information</p>
            </div>
        </template>
    </PopupWindow>
    </div>
    
    
</template>
<script>
import PopupWindow from './popupWindow.vue';

export default{
    components:{
        PopupWindow,
    },
    data(){
        return{
            isHovering:false,
            file: "",
            name:"",
            location: "",
            birdName: "",
            isSuccess: false,
            isUpload: false,
        }
    },
    methods:{
        onFileChange(event) {
            this.file = event.target.files[0];
        },
        highlight(){
            this.isHovering = true
        },
        unhighlight() {
            this.isHovering = false;
        },
        handleDrop(event){
            this.file = event.dataTransfer.files[0]; 
        },
        async submitForm() {
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('name', this.name);
            formData.append('spot', this.location);
            formData.append('birdName', this.birdName);
            try {
            const response = await fetch('https://api.symbiosisbirdweb.com/upload', {
                method: 'POST',
                body: formData
            });
            const result = await response.json();
            if(result.state === "success"){
                this.isSuccess = true;
                this.isUpload = true;
                this.cleanUp()
            }else{
                this.isSuccess = false;
                this.isUpload = true;
            }
            this.resetFileInput()
            } catch (error) {
                console.error('Error:', error);
            }
        },
        deleteModal(){
            this.isSuccess = false;
            this.isUpload = false;
        },
        cleanUp(){
            this.location = ""
            this.birdName = "",
            this.name = ""
            this.file = ""
        }
    },
    computed:{
        mode(){
            return this.$store.state.mode
        },
        pl1(){
            if(this.mode === "english"){
                return "Names&XiaohongshuID"
            }
            return "名字&小红书账号"
        },
        pl2(){
            if(this.mode === "english"){
                return "Bird Name"
            }
            return "鸟类名称"
        },
        pl3(){
            if(this.mode === "english"){
                return "Place for Photography"
            }
            return "拍摄地点"
        },
        showModal(){
            if(this.isUpload){
                return true;
            }
            return false;
        }
    }
}
</script>

<style scoped>
#img1{
    width: 40vw;
    float: right;
    margin-top: 100px;
}
#app{
    margin-left: 17%;
    width: 70%;
}
h2{
    margin-top: 0px;
    font-size: 30px;
    margin-bottom: 10px;
}
#p1{
    font-size: 20px;
    margin: 0px;
    margin-bottom: 35px;
}
#input-container{
    text-align: center;
    background-color: white;
    max-height: 435.2px;
    max-width: 348px;
    width: 30%;
    height: 38%;
    padding: 3.45%;
    border-radius: 4%;
    padding-top: 4.3%;
    font-size: 1vw;
}

input[type="text"] {
    display: block;
    margin-bottom: 20px;
  /* width: 351px; */
  width: 81%;
  height: 4.4%;
  padding: 2.3%;
  border: 2px solid #e0e0e0; /* 边框颜色 */
  border-radius: 6px; /* 圆角 */
  font-size: 100%;
  background-color: #f9f9f9; /* 背景颜色 */
  box-shadow: none; /* 去掉默认的阴影 */
  outline: none; /* 去掉点击时的外框 */
  transition: border-color 0.3s ease;
  margin-left: auto;
  margin-right: auto;
  
}

input[type="text"]::placeholder {
  color: #9e9e9e; /* 占位符文本颜色 */
}
input[type="text"]:focus {
  border-color: #333333; /* 聚焦时的边框颜色 */
  color: #333333; /* 聚焦时的文本颜色 */
}
#file-upload{
    border: 2px dashed #ccc;
  border-radius: 6px;
  width: 76.1%;
  height: 33.21%;
  font-family: Arial, sans-serif;
  text-align: center;
  padding: 4.6%;
  margin: 6.9% auto;
  

}
#file-upload.hover {
  border-color: #333;
  background-color: #f0f0f0;
}

img{
    width: 21%;
}
#labelForSelect{
    width: 41%;
    height:17.6%;
    background-color: #79DFD8;
    border-style: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    margin-top: 3.1%;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    padding: 2.5% 5%;
}
p{
    margin: 2.4%;
}
#submit{
    margin: 0 auto;
    background-color:#4D424A ;
    color: #FFFFFF;
    width: 86.2%;
    border-radius: 5px;
    padding: 2.8% 0px;
    font-size: 93.75%;
    cursor: pointer;
    border: none;
    transition: transform 0.4s ease;
}
#submit::after {
    content: '→';
    right: 1em;
    font-size: 1em;
    line-height: 1;
    color:#FFFFFF;
}
#submit:hover{
    transform: scale(1.05);
}
#success{
    width:100%;
    margin-top: 40px;
    text-align: center;
    font-size: 40px;
    margin-bottom: 20px;
}
#success p{
    font-size: 20px;
    color: red;
}
</style>