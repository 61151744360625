<template>
    <div id="bg">
        <button @click="changeMode" id="changeMode"></button>
        <div id="circle">{{ mode==="chinese" ? "中/英":"ENG/CN"}}</div>
        <div id="navigation">
            <p id="logo" style="font-family: Verdana, sans-serif;"><strong>SYMBIOSIS</strong></p>
            <ul>
                <li><router-link class="Link" to="/gtb"><span>{{ mode==="chinese" ? "看图猜鸟":"Guess The Bird"}}</span></router-link></li>
                <li><router-link class="Link" to="/upload"><span>{{ mode==="chinese" ? "贡献图片":"Contribute Pictures"}}</span></router-link></li>
                <li><router-link class="Link" to="/map"><span>{{ mode==="chinese" ? "观鸟点":"Bird Watching Spots"}}</span></router-link></li>
                <li class="Link dropdown" @mouseenter="museumMouseEnter" @mouseleave="museumMouseLeave">
                    <span >
                        {{ mode==="chinese" ? "博物馆":"Museums"}}
                    </span>
                    <div class="drop-content" v-if="museumHover">
                        <router-link id="option" to="/museum1">{{ mode==="chinese" ? "休斯顿自然科学博物馆":"Houston Natural Science Museum"}}</router-link>
                        <router-link id="option" to="/museum2">{{ mode==="chinese" ? "国家动物博物馆":"National Zoological Museum"}}</router-link>
                    </div>
                </li>
                <li><router-link class="Link" to="/birds"><span>{{ mode==="chinese" ? "图鉴":"Birds"}}</span></router-link></li> 
                <li><router-link class="Link" to="/"><span>{{ mode==="chinese" ? "主页":"Home"}}</span></router-link></li>

            </ul>
        </div>
        <div class="view">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>


export default{
    data(){
        return{
            museumHover: false,
            rs: "",
        }
    },
    methods:{
        museumMouseEnter(){
            this.museumHover = true
        },
        museumMouseLeave(){
            this.museumHover = false
        },
        changeMode(){
            this.$store.commit('changeMode')
        }
    },
    computed:{
        mode(){
            return this.$store.state.mode
        }
    },
}
</script>

<style>
#changeMode{
    position: fixed;
    right: 2vw;
    bottom: 2vw;
    border-radius: 100%;
    height: 7vw;
    width: 7vw;
    border-style: none;
    background-color: antiquewhite;
    font-size: 1.3vw;
    cursor: pointer;
    z-index: 1000;
    /* position: fixed;
    top: 0;
    right: 0;
    font-size: 16px;
    border-style: none;
    padding: 10px 20px;
    border-radius: 0 0px 0 10px;
    background-color: antiquewhite; */
}
#circle{
    display: flex;
    justify-content: center; /* Horizontally centers the content */
    align-items: center; 
    position: fixed;
    right: 2vw;
    bottom: 2vw;
    border-radius: 100%;
    height: 7vw;
    width: 7vw;
    border-style: none;
    background-color: antiquewhite;
    font-size: 1.3vw;
    cursor: pointer;
    z-index: 1001;
    pointer-events: none;
    opacity: 0.5;
}

#circle:hover

#bg{
    width: 70%;
    padding: 0;
}
body{
    width: 100%;
    margin: 0;
    padding: 0;
    background: url('assets/bg.png') no-repeat center center fixed;
    background-size: cover;
    /* font-family: Verdana, sans-serif; */
    font-family: "宋体", "SimSun", sans-serif;

}
#logo{
    font-size: 23px;;
    float: left;
    margin-top: 10px;;
}
#navigation{
    margin-left: 14%;
    position: fixed;
    width: 70%;
    color: black;
    z-index:1000;
    margin-top: 0px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 0px 0px 10px 10px;
}

#navigation ul{
    padding: 0px;
    margin-top: 15px;
    list-style-type: none;
    overflow: hidden;
}
#navigation li{
    text-decoration: none;
    margin-left: 30px;
    float: right;
    transition: all 0.3s ease;
}
.Link{
    text-decoration: none;
    color: black
}
.view{
    overflow-y: auto;
    position: absolute;
    top: 50px;
    width: 100%;
    height: 90%;
    margin-bottom: 0px;
    bottom: 0px;
}
* ::-webkit-scrollbar{
    width: 0;
}
.dropdown::after{
    content: ' \25BC'; /* Unicode for downward arrow */
    font-size: 12px;
    color: black;
    margin-left: 5px;
}
.drop-content{
    position: absolute;
    background-color: #f9f9f9;
    min-width: 70px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1000;
    text-align: center;
}
.drop-content  #option{
    color: black;
    padding: 12px;
    text-decoration: none;
    display: block;
}
.drop-content  #option :hover{
    background-color: #f1f1f1;
}
</style>
