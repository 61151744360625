<template>
  <div class="hello">
    <div class="symbiosis-page">
      <!-- Banner Section -->
      <div class="banner-content">
        <h1 class="animated-title">
          <span>S</span>
          <span>Y</span>
          <span>M</span>
          <span>B</span>
          <span>I</span>
          <span>O</span>
          <span>S</span>
          <span>I</span>
          <span>S</span>
        </h1>
        <p>Discover the nature of bird</p>
        <router-link to="#About" class="banner-button" style="text-decoration: none;">What is symbiosis?</router-link>
      </div>
      <section class="banner">
        <!-- Slider main container -->
        <swiper
            :modules="modules"
            :slides-per-view="1"
            autoplay
            :pagination="{ clickable: true }"
        >
          <swiper-slide> <img src="../assets/images/banner-1.png" alt="Bird image" class="banner-image" /></swiper-slide>
          <swiper-slide> <img src="../assets/images/banner-2.png" alt="Bird image" class="banner-image" /></swiper-slide>
          <swiper-slide> <img src="../assets/images/banner-3.png" alt="Bird image" class="banner-image" /></swiper-slide>
          <swiper-slide> <img src="../assets/images/banner-4.jpg" alt="Bird image" class="banner-image" /></swiper-slide>
          <swiper-slide> <img src="../assets/images/banner-5.png" alt="Bird image" class="banner-image" /></swiper-slide>
        </swiper>
      </section>

      <!-- About Section -->
      <section class="about-section" id="About">
        <div class="about-content">
          <div class="about-content-intro-left animated"
               :class="{ 'animate-float': sectionsVisible.about1 }"
               v-observe-visibility="{
             callback: (isVisible) => onVisibilityChange(isVisible, 'about1'),
              throttle: 200
           }"
          >
            <h2>Who are we?</h2>
            <p>
              We are the SymBIOsis team, currently on a mission to create a live map of museums and bird-watching sites around the globe.
            </p>
            <!-- <button class="details-button">More Details</button> -->
          </div>
          <div class="about-content-image">
            <img src="../assets/images/bird-2.png" alt="Bird 2" class="bird-2"/>
            <div class="bird-group">
              <img src="../assets/images/bird-4.png" alt="Bird 4" class="bird-4" />
              <img src="../assets/images/bird-3.png" alt="Bird 3" class="bird-3" />
            </div>
          </div>
        </div>
        <div class="about-content">
          <img class="about-content-image" src="../assets/images/bird-1.png" alt="Bird 1" />
          <div class="about-content-intro animated"
               :class="{ 'animate-float': sectionsVisible.about2 }"
               v-observe-visibility="{
                 callback: (isVisible) => onVisibilityChange(isVisible, 'about2'),
                 throttle: 200
               }"
          >
            <h2>Who are we?</h2>
            <p>
              We are dedicated to raising public awareness of bird biodiversity and empowering communities to actively engage in sustainable development and conservation initiatives. Join Us from Around the World!
            </p>
            <!-- <button class="details-button">More Details</button> -->
          </div>
        </div>
      </section>

      <!-- Content Section -->
      <section class="content-section">
        <h2>Contents</h2>
        <div class="content-grid animated"
             :class="{ 'animate-float': sectionsVisible.content }"
             v-observe-visibility="{
               callback: (isVisible) => onVisibilityChange(isVisible, 'content'),
               throttle: 200
             }"
        >
          <div class="content-item">
            <div class="content-item-image-container">
              <img src="../assets/images/content-1.png" alt="Content 1" style="object-fit: contain" />
            </div>
            <h3>Hand-drawn Illustration</h3>
          </div>
          <div class="content-item">
            <div class="content-item-image-container">
              <img src="../assets/images/content-2.png" alt="Content 2" />
            </div>
            <h3>Museums</h3>
          </div>
          <div class="content-item">
            <div class="content-item-image-container">
              <img src="../assets/images/content-3.png" alt="Content 3" />
            </div>
            <h3>Birdmap</h3>
          </div>
          <div class="content-item">
            <div class="content-item-image-container">
              <img src="../assets/images/content-4.png" alt="Content 4" />
            </div>
            <h3>Guess The Bird</h3>
          </div>
        </div>
        <!-- <button class="details-button">More Details</button> -->
      </section>

      <!-- Influence Section -->
      <section class="influence-section">
        <h2>Our Influence</h2>
        <img src="../assets/images/world-map.png" alt="World map with influence markers" class="world-map" />
        <div class="influence-stats animated"
             :class="{ 'animate-float': sectionsVisible.influence }"
             v-observe-visibility="{
               callback: (isVisible) => {
                 if (isVisible) {
                   this.activeMembers = 23;
                   this.birdSpeciesRecorded = 100;
                   this.regions = 19;
                   this.socialMediaViews = 20;
                 }
                 onVisibilityChange(isVisible, 'influence')
               },
               throttle: 200,
             }"
        >
          <div class="influence-stats-item">
            <h3><countTo class="count-number" :startVal='0' :endVal='activeMembers' :duration='3000'></countTo> Active Members</h3>
            <h3><countTo class="count-number" :startVal='0' :endVal='birdSpeciesRecorded' :duration='3000'></countTo>+ Bird Species Recorded</h3>
            <h3><countTo class="count-number" :startVal='0' :endVal='regions' :duration='3000'></countTo> Regions</h3>
          </div>
          <div class="influence-stats-item">
            <h3><countTo class="count-number" :startVal='0' :endVal='socialMediaViews' :duration='3000'></countTo>k+ Social media views</h3>
            <h3>Weekly content on species&conservation</h3>
          </div>
        </div>
      </section>

      <section class="tx-browsing">
        <p>Thank You For Browsing This Website</p>
        <button class="details-button">Learn More</button>
      </section>
    </div>
    <Footer />
  </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import 'swiper/swiper-bundle.css';
import { ObserveVisibility } from 'vue3-observe-visibility';
import { CountTo } from 'vue3-count-to';
// import Swiper core and required modules
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default defineComponent({
  name: 'HomePage',
  components: {
    CountTo,
    Swiper,
    SwiperSlide
  },
  directives: {
    ObserveVisibility,
  },
  setup() {
    const sectionsVisible = ref({
      banner: false,
      about1: false,
      about2: false,
      content: false,
      influence: false,
    });

    const activeMembers = ref(0);
    const birdSpeciesRecorded = ref(0);
    const regions = ref(0);
    const socialMediaViews = ref(0);

    const onVisibilityChange = (isVisible, section) => {
      if (isVisible) {
        sectionsVisible.value[section] = true;
      }
    };

    onMounted(() => {

    });

    return {
      sectionsVisible,
      activeMembers,
      birdSpeciesRecorded,
      regions,
      socialMediaViews,
      onVisibilityChange,
      modules: [Navigation, Pagination, Autoplay],
    };
  },
});
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.symbiosis-page {
  /* Page layout styling */
}

.banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f0f0;
}

.banner-content {
  position: absolute;
  left: 100px;
  top: 150px;
  z-index: 9;
  color: white;
}

.banner-content h1 {
  font-size: 7vw;
  font-weight: bold;
  color: #fff; /* Foreground color */
  position: relative;
  margin: 0;
}

.banner-content p {
  font-size: 30px;
  margin: 10px 0 30px;
}

.banner-content h1:before {
  content: "SYMBIOSIS";
  position: absolute;
  top: -35px;
  left: 0;
  color: transparent;
  -webkit-text-stroke: 1px #a1a1a1; /* Thin outline color */
  z-index: -1;
}

.banner-button {
  padding: 15px 20px;
  background: linear-gradient(90deg, rgba(218,136,114,1) 0%, rgba(136,221,214,1) 100%);
  border: none;
  color: #333333;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 40px;
}

.swiper {
  width: 100%;
  height: 700px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.banner-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-section {
  display: flex;
  justify-content: center;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(214,173,170,1) 100%);
  padding: 40px 100px;
}

.about-content {
  flex: 1;
  margin-bottom: 20px;
}

.about-content h2 {
  color: #c6795b;
  font-size: 30px;
}

.about-content-image {
  display: flex;
  align-items: center;
  width: 100%;
}

.about-content-intro-left {
  padding-right: 30px;
}

.about-content-intro {
  padding-left: 30px;
}

.bird-2 {
  width: 50%;
  height: 32vw;
  object-fit: cover;
  object-position: top;
}

.bird-group {
  width: 50%;
  font-size: 0;
}

.bird-3 {
  width: 100%;
  height: 16vw;
  object-fit: cover;
}

.bird-4 {
  width: 100%;
  height: 16vw;
  object-fit: cover;
}

.details-button {
  padding: 8px 16px;
  background-color: #69c4d4;
  border: none;
  color: white;
  cursor: pointer;
  border-radius: 16px;
  margin-bottom: 30px;
}

.content-section {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(149,207,221,1) 100%);
  padding: 40px 100px;
  text-align: center;
}

.content-section h2 {
  font-size: 30px;
  margin: 60px 0;
}

.content-grid {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
}

.content-item {
  flex: 1;
  text-align: center;
}

.content-item img {
  width: 100%;
  height: 20vw;
  object-fit: cover;
}

.content-item-image-container {
  background-color: #837fa5;
  border-radius: 20px 20px 0 0;
  font-size: 0;
  overflow: hidden;
}

.content-item h3 {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(218,136,114,1) 0%, rgba(136,221,214,1) 100%);
  margin: 0;
  padding: 10px;
  height: 50px;
}

.content-section .details-button {
  font-size: 25px;
  margin-top: 80px;
  margin-bottom: 50px;
  border-radius: 20px;
}

.influence-section {
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(205,232,239,1) 100%);
  padding: 40px 20px;
  text-align: center;
}

.influence-section h2{
  font-size: 30px;
  margin: 60px 0;
}

.world-map {
  max-width: 100%;
  height: auto;
}

.influence-stats {
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  margin-bottom: 50px;
}

.influence-stats-item {
  width: 40vw;
}

.influence-stats-item h3 {
  font-size: 30px;
}

.count-number {
  font-size: 40px;
}

.tx-browsing {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: rgb(255,255,255);
  background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(205,153,150,1) 100%);
}

.tx-browsing p {
  font-size: 20px;
  font-weight: bold;
}

.tx-browsing .details-button {
  margin-bottom: 20px;
}

.swiper {
  --swiper-pagination-color: #90d3e0;
  --swiper-pagination-bullet-color: #333333;
  --swiper-pagination-bullet-inactive-opacity: 0.6;
  --swiper-pagination-bullet-size: 15px;
}


@keyframes floatIn {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated {
  opacity: 0;
  transition: opacity 0.3s ease;
}

.animate-float {
  animation: floatIn 2s ease forwards;
}

.animated-title {
  display: flex;
  font-size: 7vw;
  font-weight: bold;
  color: #fff;
  position: relative;
}

.animated-title span {
  display: inline-block;
  opacity: 0;
  transform: scale(0.5);
  animation: fadeInScale 0.5s ease forwards;
}

@keyframes fadeInScale {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.animated-title span:nth-child(1) { animation-delay: 0s; }
.animated-title span:nth-child(2) { animation-delay: 0.1s; }
.animated-title span:nth-child(3) { animation-delay: 0.2s; }
.animated-title span:nth-child(4) { animation-delay: 0.3s; }
.animated-title span:nth-child(5) { animation-delay: 0.4s; }
.animated-title span:nth-child(6) { animation-delay: 0.5s; }
.animated-title span:nth-child(7) { animation-delay: 0.6s; }
.animated-title span:nth-child(8) { animation-delay: 0.7s; }
.animated-title span:nth-child(9) { animation-delay: 0.8s; }
</style>
